import React from 'react';

import './home.css';
import Footer from '../../components/footer/Footer';
import Cards from '../../UI/Cards';
import { speciality } from '../../data';

import impact_img from '../../assets/images/achivements/awards_collection.png';
import award1 from '../../assets/images/achivements/tamil_nadu_tanseed.png';
import award2 from '../../assets/images/achivements/social_impact.png';
import main_bg_right from '../../assets/images/main_bg_right.png'
import doctorWithNurse from "../../assets/images/doctors/doctor_with_nurse.png"
import collaborationImage1 from "../../assets/images/collaborations/sicci.jpeg"
import collaborationImage2 from "../../assets/images/collaborations/crescent.jpeg"





const Home = () => {
  return (
    <div>
      <section className="home_page_main">
        <div className="main_container flex">
          <div className="left_main">
            <h2>No <br /> Delay in <br /> <span>Healthcare</span> </h2>
            <p className='italic font-medium text-lg'>Empowering people and the community for a healthy life</p>
            <button className='home_page_btn font-bold text-base'> READ MORE </button>
          </div>

          <div className="right_main flex">
            <img src={main_bg_right} alt="" className='image1' />
          </div>
        </div>
      </section>

      <section className="quality_healthCare flex">
        <img src={doctorWithNurse} alt="" />
        <div className="quality_health_contents">
          <h3 className='font-bold'>Revolutionizing Healthcare</h3>
          <p className='text-justify'>Our focus is on creating Tele-diagnostic devices which enable patients to receive consultations and diagnoses from nearby pharmacies and clinics, eliminating the requirement for extensive travel and enhancing access to quality healthcare for rural and semi-urban populations. As a result, patients can receive the necessary treatments promptly, ensuring optimal care.</p>
          <button className='home_page_btn font-bold text-base'> READ MORE </button>
        </div>
      </section>

      <section className="speciality">
        <div className="container speciality_container flex flex-col items-center">
          <h3 className="text-black text-3xl font-bold mb-4">Our Services</h3>
          {/* <p className="text-base font-normal w-3/4 text-center mb-4 leading-7">Ask a doctor online and get quick medical advice for your health queries. </p> */}
        </div>

        <div className='Speciality_wrapper'>
          {
            speciality.map(({ id, image, title }) => {
              return (
                <Cards className="speciality_cards flex flex-col" key={id} >
                  <span> {image} </span>
                  <h4 className='font-bold'> {title} </h4>
                </Cards>
              )
            })
          }
        </div>
      </section>


      <section className="achievments">
        <div className="achiev_container flex flex-col items-center">
        <h3 className="text-black text-3xl font-bold mb-4">Our Achievements</h3>
          <img src={impact_img} alt="" className='impact_img' />
          <div className="awards_image flex">
            <img src={award1} alt="" />
            <img src={award2} alt="" />
          </div>
        </div>
      </section>
      <section className="collaboration">
        <div className="container collaboration_container flex flex-col items-center">
          <h3 className="text-black text-4xl font-bold mb-10 mt-10">Our Collaboration & Partner</h3>
          <div className="awards_image flex">
            <img src={collaborationImage1} alt="" />
            <img src={collaborationImage2} alt="" className="collaboration_image_2" />
          </div>
        </div>
      </section>
      {/* <section className="blank"></section> */}
      <Footer />
    </div>
  )
}

export default Home;

import img4 from './assets/images/img_image89.png';



// Doctor Images


// Icons
import follow_checkup from './assets/images/icons/follow_checkup.png';
import health_wallet from './assets/images/icons/health_wallet.png';
import plus_red_and_orange from './assets/images/icons/plus_red_and_orange.png';
import online_consult from './assets/images/icons/online_consult.png';
import vitals_parameters from './assets/images/icons/vitals_parameters.png';
import online_appointment from './assets/images/icons/online_appointment.png';
import vital_history from './assets/images/icons/vital_history.png';

// Services
import appService from "./assets/images/services/app_service.jpeg"
import tdmService from "./assets/images/services/tdm_service.png"
import uhiService from "./assets/images/services/uhi_service.png"

// Team Images
import pavan from './assets/images/team/pavan.png';
import rahul_soni from './assets/images/team/rahul_soni.png';
import defaultImage from "./assets/images/team/default.jpeg"
import anmol_garg from "./assets/images/team/anmol_garg.jpeg";



export const links = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "About",
        path: "/about"
    },
    {
        name: "Product",
        path: "/product"
    },
    {
        name: "Team",
        path: "/team"
    },
    {
        name: "Our Services",
        path: "/our-services"
    },
    {
        name: "Contact",
        path: "/contact"
    }
]




export const logInLi = [
    {
        name: "Patient",
        path: "/"
    },
    {
        name: "Doctor",
        path: "/product"
    },
    {
        name: "Operator",
        path: "/team"
    }
]






export const serviceCards = [
    {
        id: 1,
        image: <img src={tdmService} /> ,
        title: "TDM Services",
        // info: "Upgrading health infrastructure, such as small clinics and pharmacies"
    },
    {
        id: 2,
        image: <img src={appService} /> ,
        title: "App Services",
        // info: "Lorem Ipsum is simply dummy text of the printing typesetting psum has been the industry."
    },
    {
        id: 3,
        image: <img src={uhiService} /> ,
        title: "Integrated Services",
        // info: "Lorem Ipsum is simply dummy text of the printing typesetting psum has been the industry."
    },
    {
        id: 4,
        image: <img src={img4} /> ,
        title: "UHI",
        // info: "Lorem Ipsum is simply dummy text of the printing typesetting psum has been the industry."
    },
]





export const teamsCards = [
    {
        id: 1,
        image: <img src={anmol_garg} style={{width: "85%"}} /> ,
        name: "Anmol Garg",
        desig: "Co-founder & CEO",
        info: "Am thrilled to be leading this exciting venture in the field of digital healthcare technology. We believe that by combining our expertise in health and technology, we can create a platform that will revolutionize the healthcare industry. Whether it's through developing new diagnostic tools, or improving patient communication and collaboration with healthcare providers, we're committed to delivering innovative solutions that make a real difference in people's lives.We look forward to building a future where technology and health are seamlessly integrated, making healthcare more affordable, accessible, and effective for everyone."
    },
    {
        id: 2,
        image: <img src={rahul_soni} /> ,
        name: "Rahul Soni",
        desig: "Co-founder & CTO",
        info: "As the CTO at Bridge Healthcare, I am thrilled to share the incredible advancements in technology that are transforming the way we approach healthcare. I am proud to be leading a talented and dedicated team who shares the same vision. Our team includes experts in medicine, engineering, and design, who are working tirelessly to bring our ideas to life. However, while we are making great strides in making tele-diagnostic devices and applications of technology in healthcare, it is crucial that we approach these advancements with caution. The implementation of health tech must prioritize privacy and security, as well as ensure that these tools are accessible to all populations, regardless of socioeconomic status."
    }
]





export const teamMembers = [
    {
        id: 1,
        image: <img src={defaultImage} alt='Images Team_Member' /> ,
        name: "Dr. Rebbeca",
        desig: "Director"
    },
    {
        id: 2,
        image: <img src={defaultImage} alt='Images Team_Member' /> ,
        name: "Satyanarayana",
        desig: "CKO"
    },
    {
        id: 3,
        image: <img src={defaultImage} alt='Images Team_Member' /> ,
        name: "Bhaskar",
        desig: "R&D Head"
    },
    {
        id: 4,
        image: <img src={pavan} alt='Pavan Team_Member' /> ,
        name: "pavan",
        desig: "Senior developer"
    },
    {
        id: 5,
        image: <img src={defaultImage} alt='Images Team_Member' /> ,
        name: "Satyendra",
        desig: "Creative Head"
    },
    {
        id: 6,
        image: <img src={defaultImage} alt='Images Team_Member' /> ,
        name: "Divyanshu",
        desig: "Adviser"
    },
    {
        id: 7,
        image: <img src={defaultImage} alt='Images Team_Member' /> ,
        name: "Dr. Mukund Sriniwas",
        desig: "Social Media"
    },
]






export const speciality = [
    {
        id: 1,
        image: <img src={online_consult} alt='online_consult' />,
        title: "Online Consultation"
    },
    {
        id: 2,
        image: <img src={vitals_parameters} alt='vitals_parameters' />,
        title: "Live Vitals Parameters"
    },
    {
        id: 3,
        image: <img src={online_appointment} alt='online_appointment' />,
        title: "Online Appointment"
    },
    {
        id: 4,
        image: <img src={plus_red_and_orange} alt='primary_health_checkup' />,
        title: "Primary Health check up"
    },
    {
        id: 5,
        image: <img src={follow_checkup} alt='follow_checkup' />,
        title: "Follow Check up"
    },
    {
        id: 6,
        image: <img src={health_wallet} alt='health_wallet' />,
        title: "Health Wallet"
    },
    {
        id: 7,
        image: <img src={vital_history} alt='vital_history' />,
        title: "Vital History"
    }
]


import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

import "./footer.css";
import Logo from "../../assets/images/img_untitleddesign.png";
import { Line } from "../line/Line";

const Footer = () => {
  return (
    <footer className="footer_container">
      <div className="footer_content flex items-center">
        <div className="container flex justify-between">
          <div className="item-1st">
            <Link to="/" className="logo nav_logo mb-5">
              <img src={Logo} alt="Nav Logo" />
              <Line className="vertical_line" />
              <p className="nav_logo-text">
                Bridge HealthCare <br /> Private Limited
              </p>
            </Link>

            <div className="icons flex flex-row">
              <a
                href="https://www.facebook.com/profile.php?id=100087728966427"
                className="icons_elt"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.linkedin.com/company/bridge-healthcare-pvt-ltd"
                className="icons_elt"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>

          <div className="items-link text-black flex flex-col">
            <h3>Our links</h3>
            <div className="flex flex-col justify-center align-middle">
              <a
                href="/about"
                className="text-black flex flex-row justify-center"
              >
                About Us
              </a>
              <a
                href="/product"
                className="text-black flex flex-row justify-center"
              >
                Product
              </a>
            </div>
          </div>

          <div className="items-link">
            <h3>Your Account</h3>
            <a
              href="/contact"
              className="text-black flex flex-row justify-center"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>

      <div className="footer_content_mini flex flex-col justify-center">
        <p className="text-center text-xl font-black">
          Bridge Healthcare Private Limited{" "}
        </p>
        <p className="text-center text-xl font-black">
          13B/8, 4th Main Road, Indira Nagar, Adyar, Chennai, TN - 600020
        </p>
      </div>

      <div className="copyright flex items-center p-10">
        <div className="container flex justify-between">
          <p>© Copyright 2022 Bridge Healthcare Private Limited</p>
          <ul className="flex gap-8">
            <li>Privacy Policy</li>
            <li>Terms Of Use</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';

import './notFound.css';

const NotFound = () => {
  return (
    <div>
     NotFound
    </div>
  )
}

export default NotFound